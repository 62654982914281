<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img fluid :src="logoImg" style="height: 30px"></b-img>
        <h2 class="brand-text text-primary ml-1">呱咖运营管理后台</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            登陆呱咖
          </b-card-title>
          <!-- form -->
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- account -->
            <b-form-group label-for="h-username">
              <b-form-input
                id="h-username"
                v-model="userLogin.username"
                placeholder="管理员账号"
              />
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <!-- <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password-v2'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div> -->
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="login-password"
                  v-model="userLogin.password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                  placeholder="管理员密码"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              @click="loginBtn(userLogin)"
            >
              登陆
            </b-button>
          </b-form>

          <!-- <b-card-text class="text-center mt-2">
            <span>还没有账号? </span>
            <b-link href="https://iguaka.com/product.html" target="_blank">
              <span>&nbsp;点击此处下载注册</span>
            </b-link>
          </b-card-text> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { guest } from "@/utils/api";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      logoImg: require("@/assets/images/logo/guakalogo2.png"),
      // validation rulesimport store from '@/store/index'
      userLogin: {
        username: "",
        password: "",
      },
    };
  },
  computed: {
    //密码显示隐藏icon
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    makeToast(variant = null, tip) {
      this.$bvToast.toast(tip, {
        title: `账号错误`,
        variant,
        solid: true,
      });
    },
    loginBtn(userLogin) {
      if (!userLogin.username) {
        let tip = "账号不能为空";
        this.makeToast("warning", tip);
        return;
      } else if (!userLogin.password) {
        let tip = "密码不能为空";
        this.makeToast("warning", tip);
        return;
      } else {
        //登陆请求
        guest("user.oaLogin", userLogin).then((res) => {
          if (res) {
            console.log(res, "loginres");
            if (res.data.code !== "200") {
              // let tip = "网络请求错误";
              this.makeToast("warning", res.data.msg);
              return;
            }
            // if (res.data.code === "400") {
            //   // let tip = "账号或密码错误";
            //   this.makeToast("warning", res.data.msg);
            //   return;
            // }
            if (res.data.code === "200") {
              // if (res.data.data.user.utype !== "admin") {
              //   this.makeToast("warning", "没有后台登陆权限");
              //   return;
              // }
              let userInfo = res.data.data.user;
              let token = res.data.data.user.token;
              let store = res.data.data.store;
              this.$store.commit("SET_TOKEN", token);
              this.$store.commit("SET_USER_INFO", userInfo);
              this.$store.commit("SET_STORE", store);
              this.$router.push({ name: "errandManagement" });
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "欢迎使用呱咖运营管理后台",
                  icon: "EditIcon",
                },
              });
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
